<template>
  <div class="dropdown-menu dropdown-menu-arrow dropdown-menu-right">
    <!-- <div class=" dropdown-header noti-title"> -->
    <!--   <h6 class="text-overflow m-0">Welcome!</h6> -->
    <!-- </div> -->
    <!-- <a href="./examples/profile.html" class="dropdown-item"> -->
    <!--   <i class="ni ni-single-02"></i> -->
    <!--   <span>My profile</span> -->
    <!-- </a> -->
    <!-- <a href="./examples/profile.html" class="dropdown-item"> -->
    <!--   <i class="ni ni-settings-gear-65"></i> -->
    <!--   <span>Settings</span> -->
    <!-- </a> -->
    <!-- <a href="./examples/profile.html" class="dropdown-item"> -->
    <!--   <i class="ni ni-calendar-grid-58"></i> -->
    <!--   <span>Activity</span> -->
    <!-- </a> -->
    <!-- <a href="./examples/profile.html" class="dropdown-item"> -->
    <!--   <i class="ni ni-support-16"></i> -->
    <!--   <span>Support</span> -->
    <!-- </a> -->
    <!-- <div class="dropdown-divider"></div> -->
    <router-link to="/dental-clinic/profile" class="dropdown-item">
      <i class="fa fas fa-cog"></i>
      Perfil
    </router-link>
    <a
      href="javascript:void(0)"
      @click.prevent="logout()"
      class="dropdown-item"
    >
      <i class="ni ni-user-run"></i>
      <span>Cerrar sesión</span>
    </a>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    //
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    ...mapActions({
      authLogout: "auth/logout",
    }),
    async logout() {
      await this.authLogout();
      this.$router.push("/login");
    },
  },
};
</script>

<style></style>
